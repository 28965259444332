
<template>
    <div>
        <!-- <CRow v-if="slug == 'signing-list' && documents.length > 0">
            <CCol lg="3">
                <a-popover 
                    title="More Options" 
                    placement="right"
                    style="
                        padding: 10px;
                        border: 2px solid black;
                        border-radius: 12px;
                        background-color: lightgrey;
                        color:whited
                    "
                >
                    <template slot="content">
                        <CButton 
                            v-if="$store.getters.can('document-routing-manage-multiple-signatory')"
                            block
                            size="sm"
                            color="secondary" 
                            @click="getMyForSigningTemplateName()"
                        >
                            <font-awesome-icon icon="user-slash"/> Multiple Change Signatory
                        </CButton>
                    </template>
                    <font-awesome-icon icon="bars" size="lg"  style="margin:3px; "/> More Options
                </a-popover>
            </CCol>
            <CCol lg="9">
            </CCol>
        </CRow> -->
        <CRow >
            <CCol lg="3">
                <!-- <a-popover 
                    title="More Options" 
                    placement="right"
                    style="
                        padding: 10px;
                        border: 2px solid black;
                        border-radius: 12px;
                        background-color: lightgrey;
                        color:whited
                    "
                >
                    <template slot="content"> -->
                        <!-- Refresh Data -->
                <!-- <CButton
                    class="float-left"
                    color="success"
                    @click="refreshDataInParentFile()"
                    title="Refresh Data"
                >
                    <font-awesome-icon icon="arrows-rotate"/>
                </CButton> -->
                    <!-- </template>
                    <font-awesome-icon icon="bars" size="lg"  style="margin:3px; "/> More Options
                </a-popover> -->
            </CCol>
            <!-- <CCol lg="5">
            </CCol> -->
            <CCol lg="9">
                <span class="float-right" v-if="display_last_refresh_date">
                    As of <strong> {{ display_last_refresh_date }}</strong>
                </span>
                <!-- {{ lastRefreshData }} -->
                <!-- {{  }} -->
            </CCol>
        </CRow>
        <br>
        <!-- <CCard> -->
        <template>
        <div class="animated fadeIn">
        <CCard :style="viewPreference == 'table' ? '' : 'display:none'">                
            <CCardBody>
                <!-- <CCardHeader><font-awesome-icon icon="list"/> Document List</CCardHeader> -->
                
                <!-- :items-per-page="12"
                    pagination
                    items-per-page-select -->
                <CDataTable   
                    :items="table_documents"
                    :fields="document_list_fields"
                    border
                    sorter
                    hover
                    @row-clicked="viewDocument"
                    :loading="table_loading"
                >
                <template #reference_number="{item}" >
                    <td class="p-1" >
                    <font-awesome-icon 
                        :style="item.document_template_color ?
                            {'color': item.document_template_color}:
                            {'color': grey}"
                        icon="tag" 
                        size="lg" 
                        v-c-tooltip="{content:item.document_template_category, placement: 'right'}"
                    />
                        <!-- {{item.reference_number}} -->
                        <span v-c-tooltip="{content:item.reference_number, placement: 'top'}">
                            {{item.reference_number.length > 45 ? item.reference_number.substring(0, 45) + '...' :  item.reference_number}}
                        </span>
                    <font-awesome-icon 
                        :class="item.return_count > 0 ? 'float-right' : 'd-none'"
                        icon="exclamation-circle" 
                        size="lg"
                        color="#f9b115" 
                        v-c-tooltip="{content: `This document has been returned ${item.return_count} time/s.`, placement: 'right'}"
                    />
                    </td>
                </template>
                <template #description="{item}">
                    <td class="p-1" v-if="item.description" :title="item.description">
                        {{ item.description.length > 15 ? item.description.substring(0, 15) + '...' : item.description}} 
                    </td>
                    <td class="p-1" v-else>
                        N/a
                    </td>
                </template>
                <template #document_template_name="{item}">
                    <td class="p-1" :title="item.document_template_name">
                        {{ item.document_template_name.length > 35 ? item.document_template_name.substring(0, 35) + '...' : item.document_template_name}}

                        <!-- {{ item.description.length > 15 ? item.description.substring(0, 15) + '...' : item.description}}  -->
                    </td>
                </template>
                <template #datetime_created="{item}">
                    <td class="p-1">
                        <small>{{ changeDateFormat(item.datetime_created) }}</small>
                    </td>
                </template>
                <template #datetime_updated="{item}">
                    <td class="p-1">
                        <small>{{ changeDateFormat(item.datetime_updated) }}</small>
                    </td>
                </template>
                <template #progress="{item}">
                    <td class="p-1">
                            <!-- <small class="float-right" style="font-size: 60%;"><b>{{ item.signature_ratio }}</b></small> -->
                            <!-- <br> -->
                                <!-- show-value 
                                showPercentage -->
                                <!-- :value="(item.percentage != null) ? parseInt(item.percentage) : 0" 
                                size="sm" 
                                show-value 
                                showPercentage
                                style="height: 20px"
                                :color="getColorStatus(slug)"
                                variant="striped" 
                                animated -->
                            <span style="margin-top: 15px;">
                                <small class="float-left" style="font-size: 60%;">Signatory</small>
                                <small class="float-right" style="font-size: 60%;" v-if="item.signatory_ratio">{{ item.signatory_ratio }}</small>
                            </span>
                            <CProgress 
                                style="height: 20px; margin-top: 15px;"
                                variant="striped" 
                                animated
                            >
                                <CProgressBar 
                                :color="getColorStatus(slug)" 
                                :value="(item.percentage != null) ? parseInt(item.percentage) : 0"> {{ item.percentage }}%</CProgressBar>
                            </CProgress>
                    </td>
                </template>
                <template #action="{item}">
                    <td style=" text-align: center; " class="p-1">
                        <!-- <CButtonGroup> -->
                            <CButton 
                                accesskey="o"
                                style="margin-right:5px"
                                class="rounded-pill"
                                color="primary" 
                                size="sm"
                                @click="openNewWindow(item.id)"
                                v-c-tooltip="{content:'Open in New Tab', placement: 'top'}"
                            > 
                                <font-awesome-icon icon="external-link-alt"/>
                            </CButton>
                            
                            <CButton 
                                style="margin-right:5px"    
                                class="rounded-pill"
                                color="success" 
                                size="sm"
                                @click="previewFile(item.id, item.reference_number)"
                                v-c-tooltip="{content:'Preview File', placement: 'top'}"
                            > 
                                <font-awesome-icon icon="eye"/>
                            </CButton>
                            
                            <CButton 
                                style="margin-right:5px" 
                                class="rounded-pill"
                                color="dark" 
                                size="sm"
                                @click="downloadFile(item.id, item.reference_number)"
                                v-c-tooltip="{content:'Download File', placement: 'top'}"
                            > 
                                <font-awesome-icon icon="download"/>
                            </CButton>
                            
                            <CButton 
                                class="rounded-pill"
                                color="warning" 
                                size="sm"
                                v-if="item.history_number > 0"
                                v-c-tooltip="{content:`View Routing History (${item.history_number})`, placement: 'left'}"
                                @click="toggleRoutingHistoryModal(item.id)"
                            > 
                                <font-awesome-icon icon="folder-tree"/>
                            </CButton>
                        <!-- </CButtonGroup> -->
                    </td>
                </template>
                </CDataTable>
            </CCardBody>
        </CCard>
        </div>
        </template>
        <CRow :style="viewPreference == 'card' ? '' : 'display:none'">
            <CCol xl="3" lg="3" md="6" sm="12" v-for="(document) in documents" v-bind:key="document.id">
                <CWidgetProgress :title="document.description">
                    <template #default>
                        <CRow>
                            <CCol lg="12">
                                <a href="javascript:void(0);" style="text-decoration: none;" @click="viewDetails(document.id)">
                                    <h4>
                                            <font-awesome-icon 
                                            :style="document.document_template_color ?
                                            {'color': document.document_template_color}:
                                            {'color': grey}"
                                            icon="tag" size="lg" v-c-tooltip="{content:document.document_template_category, placement: 'top'}"/>
                                       
                                            <span v-c-tooltip="{content:document.reference_number, placement: 'top'}">
                                                {{document.reference_number.length > 17 ? document.reference_number.substring(0, 17) + '...' :  document.reference_number}}
                                            </span>
                                             
                                            <!-- {{ item.filename.length > 15 ? item.filename.substring(0, 15) + '...' +  item.filename.split('.')[1] : item.filename }} -->
                                            <font-awesome-icon 
                                                :class="document.return_count > 0 ? 'float-right' : 'd-none'"
                                                icon="exclamation-circle" 
                                                size="lg"
                                                color="#f9b115" 
                                                v-c-tooltip="{content: `This document has been returned ${document.return_count} time/s.`, placement: 'right'}"
                                            />
                                        <!-- <a-popover 
                                            v-if="document.document_routing_remarks && document.document_routing_remarks.length > 0"
                                        >
                                            <template slot="title">
                                                <font-awesome-icon icon="signature" /> Signatories Remarks
                                            </template>
                                            <template slot="content" >
                                                <CCard  color="dark" 
                                                        style="width: 18rem; padding:10px" 
                                                        class="border-dark"
                                                        textColor='white'
                                                        v-for="(user_remarks) in document.document_routing_remarks"
                                                        v-bind:key="user_remarks.id"
                                                >
                                                    <span>{{user_remarks.remarks}}</span>
                                                    <br>
                                                    <span><i>{{user_remarks.user.first_name}} {{user_remarks.user.last_name}}</i></span>
                                                    <small>{{changeDateFormat(user_remarks.updated_at)}}</small>
                                                </CCard>
                                                
                                            </template>
                                            <font-awesome-icon style="color: #0074d9; " icon="comment-alt" class="float-right"/>
                                        </a-popover> -->

                                    </h4>
                                    <small class="text-muted" v-if="document.description">
                                        Description: {{ document.description.length > 15 ? document.description.substring(0, 15) + '...' : document.description}} 
                                    </small>
                                    <small class="text-muted" v-else>
                                        Description: N/a
                                    </small>
                                    <br>
                                    <br>
                                    <label :title="document.document_template_name">
                                        {{ document.document_template_name.length > 35 ? document.document_template_name.substring(0, 35) + '...' : document.document_template_name}}
                                    </label>
                                    <br> 
                                    <template v-if="slug == 'processing-list'">
                                        <small class="text-muted">
                                            For signing by: {{document.for_signing_first_name}} {{document.for_signing_last_name}}
                                        </small>
                                        <br>
                                    </template>
                                    <small class="text-muted">
                                        Created by: {{document.created_by_first_name}} {{document.created_by_last_name}}
                                    </small>
                                    <br>
                                    <small class="text-muted">
                                        Date Created: {{changeDateFormat(document.created_at)}}
                                    </small>
                                    <div v-if="slug != 'draft-list'">
                                        <small class="text-muted">
                                            Date Updated: {{changeDateFormat(document.updated_at)}}
                                        </small>
                                    </div>
                                </a>
                                    <hr >
                                    <CRow>
                                        <CCol lg="12">
                                            <span style="margin-top: 15px;">
                                                <small class="float-left" style="font-size: 60%;">Signatory</small>
                                                <small class="float-right" style="font-size: 60%;" v-if="document.signatory_ratio">{{ document.signatory_ratio }}</small>
                                            </span>
                                            <CProgress 
                                                style="height: 20px; margin-top: 15px;"
                                                variant="striped" 
                                                animated
                                            >
                                                <CProgressBar 
                                                :color="getColorStatus(slug)" 
                                                :value="(document.percentage != null) ? parseInt(document.percentage) : 0"> {{ document.percentage }}%</CProgressBar>
                                            </CProgress>
                                            <!-- <CProgress :value="(document.percentage != null) ? parseInt(document.percentage) : 0" 
                                            show-value 
                                            size="sm" 
                                            style="height: 20px"
                                            :color="getColorStatus(slug)"
                                            showPercentage
                                            /> -->
                                        </CCol>
                                    </CRow>
                            </CCol>
                        </CRow>
                        <br >
                        <CRow>
                            <CCol>
                                <CButton 
                                    block
                                    class="rounded-pill"
                                    color="primary" 
                                    size="sm"
                                    @click="openNewWindow(document.id)"
                                    v-c-tooltip="{content:'Open in New Tab', placement: 'top'}"
                                > 
                                    <font-awesome-icon icon="external-link-alt"/>
                                </CButton>
                            </CCol>
                            <CCol>
                                <CButton 
                                    block
                                    class="rounded-pill"
                                    color="success" 
                                    size="sm"
                                    @click="previewFile(document.id, document.reference_number)"
                                    v-c-tooltip="{content:'Preview File', placement: 'top'}"
                                > 
                                    <font-awesome-icon icon="eye"/>
                                </CButton>
                            </CCol>
                            <CCol>
                                <CButton 
                                    block
                                    class="rounded-pill"
                                    color="dark" 
                                    size="sm"
                                    @click="downloadFile(document.id, document.reference_number)"
                                    v-c-tooltip="{content:'Download File', placement: 'top'}"
                                > 
                                    <font-awesome-icon icon="download"/>
                                </CButton>
                            </CCol>
                            <CCol v-if="document.history_number > 0">
                                <CButton 
                                    block
                                    class="rounded-pill"
                                    color="warning" 
                                    size="sm"
                                    
                                    @click="toggleRoutingHistoryModal(document.id)"
                                    v-c-tooltip="{content:`Workflow  (${document.history_number})`, placement: 'top'}"
                                > 
                                    <font-awesome-icon icon="folder-tree"/>
                                </CButton>
                            </CCol>
                        </CRow>
                        <!-- <br >
                        <CRow>
                            <CCol lg="6">
                                <CButton 
                                    block   
                                    class="float-right"
                                    color="success" 
                                    size="sm"
                                    @click="previewFile(document.id, document.reference_number)"
                                > 
                                    Preview File
                                </CButton>
                            </CCol>
                            <CCol lg="6">
                                <CButton 
                                    block   
                                    class="float-left"
                                    color="dark" 
                                    size="sm"
                                    @click="downloadFile(document.id, document.reference_number)"
                                > 
                                     Download File
                                </CButton>
                            </CCol>
                        </CRow>
                        <br>
                        <CRow>
                            <CCol lg="12">
                                <CButton 
                                    block
                                    class="float-left"
                                    color="warning" 
                                    size="sm"
                                    v-if="document.history_number > 0"
                                    @click="toggleRoutingHistoryModal(document.id)"
                                > 
                                 View Routing History ({{document.history_number}})
                                </CButton>
                            </CCol>
                        </CRow> -->
                    </template>
                </CWidgetProgress>
                
            </CCol>
        </CRow>
        <CRow>
            <CCol lg="12" v-if="data_loaded && documents.length <= 0">
                <h4>No data found.</h4>
            </CCol>
        </CRow>
        <CRow>
            <CCol lg="12">
                <template v-if="slug == 'completed-list' || slug == 'processing-list'">
                    <CPagination
                        id="pajineyt"
                        :pages="number_of_pages"
                        :doubleArrows="false"
                        :dots="false"
                        :active-page.sync="current_page"
                        @update:activePage="updatePage"
                    >
                        <template slot="previous-button">
                            <font-awesome-icon icon="chevron-left"/>
                            Previous
                        </template>
                        <template slot="next-button">
                            Next
                            <font-awesome-icon icon="chevron-right"/>
                        </template>
                    </CPagination>
                </template>
                <template v-else>
                    <pagination 
                        v-model="current_page" 
                        :records="total_records" 
                        :per-page="per_page" 
                        @paginate="updatePage"
                    />
                </template>
            </CCol>
        </CRow>
        <CModal
            :show.sync="multiple_change_signatory"
            color="dark"
            size="lg"
            >
            <template #header>
                <h6> <font-awesome-icon icon="user-slash"/> Change Signatory </h6>
                <CButton 
                    class="pull-right" 
                    color="light" 
                    shape="pill" 
                    size="sm"
                    @click="multiple_change_signatory = false"
                >
                    <font-awesome-icon  size="sm" icon="times"/>
                </CButton>
            </template>
            <template #footer>
                <CRow lg="12">
                    
                    <CButton 
                        class="pull-right" 
                        color="primary" 
                        :disabled="!allowed_changed"
                        @click="allowed_changed ? submitChangeSignatory() : ''"
                    >
                        <font-awesome-icon  size="sm" icon="save"/> Submit
                    </CButton>
                    <CButton 
                        class="pull-right" 
                        color="dark" 
                        @click="multiple_change_signatory = false"
                    >
                        <font-awesome-icon  size="sm" icon="times"/> Close
                    </CButton>
                </CRow>
            </template>
             <template #body-wrapper>
                 <CRow>
                     <CCol lg="6">
                            <CCardBody>
                                <CRow>
                                    <CCol xl="12" lg="12" md="12" sm="12">
                                        <label> Select Template </label>
                                        <v-select
                                            label="name"
                                            :options="for_signing_templates"
                                            v-model="mc_signatory.selected_template"
                                            :class="!mc_signatory.selected_template ? 'has-error' : 'has-success'"
                                        />
                                    </CCol>
                                </CRow>
                                <br>
                                <CRow v-if="mc_signatory.selected_signatory">
                                    <CCol lg="12">
                                        <span style="color: red;">Please Select Document(s)*</span>
                                    </CCol>
                                    <br><br>
                                    <template v-for="(_document, index) in available_routings" >
                                        <CCol xl="6" lg="6" md="6" sm="6" v-bind:key="index">
                                            <p-check 
                                                class="p-icon p-bigger" 
                                                color="success" 
                                                :value="_document.document_routing_header_id"
                                                v-model="selected_routings"
                                                >
                                                    <font-awesome-icon class="icon" icon="check"/>
                                                    {{_document.reference_number}}
                                            </p-check> 
                                        </CCol>
                                    </template>
                                </CRow>
                            </CCardBody>
                     </CCol>
                     
                     <CCol lg="6">
                            <CCardBody>
                                <CRow>
                                    <CCol xl="12" lg="12" md="12" sm="12" v-if="available_signatories.length > 0">
                                        <label> Select new Signatory </label>
                                        <v-select
                                            label="signatory_name"
                                            :options="available_signatories"
                                            v-model="mc_signatory.selected_signatory"
                                            :class="!mc_signatory.selected_signatory ? 'has-error' : 'has-success'"
                                        />
                                    </CCol>
                                    <CCol xl="12" lg="12" md="12" sm="12" v-if="no_available_signatories && !signatory_locked">
                                        <br>
                                        <h5> Sorry, no available signatories found. </h5>
                                    </CCol>
                                    <CCol xl="12" lg="12" md="12" sm="12" v-if="signatory_locked">
                                        <br>
                                        <h5>The selected template has an activated signatory locked. Changing or Removing of signatory is not allowed.</h5>
                                    </CCol>
                                </CRow>
                                <br>
                                <CRow v-if="mc_signatory.selected_signatory && available_signatory_requirements.length > 0">
                                    <CCol lg="12">
                                        <span style="color: red;">Please Select Signatory Requirement(s)*</span>
                                    </CCol>
                                    <br><br>
                                    <template v-for="(_requirement, index) in available_signatory_requirements" >
                                        <CCol xl="6" lg="6" md="6" sm="6" v-bind:key="index">
                                            <p-check 
                                                class="p-icon p-bigger" 
                                                color="success" 
                                                :value="_requirement.id"
                                                v-model="selected_requirements"
                                                >
                                                    <font-awesome-icon class="icon" icon="check"/>
                                                    {{_requirement.requirement}}
                                            </p-check> 
                                        </CCol>
                                    </template>
                                </CRow>
                            </CCardBody>
                     </CCol>
                 </CRow>
                 <CRow>
                     <CCol lg="12">
                         <!-- v-if="mc_signatory.selected_template" -->
                         
                            <CCardBody>
                            <CRow >
                                <CCol xl="12" lg="12" md="12" sm="12">
                                    <CTextarea
                                        :disabled="!mc_signatory.selected_template || no_available_signatories || signatory_locked"
                                        label="Reason"
                                        placeholder="Content..."
                                        autocomplete="off"  
                                        rows="3"
                                        v-tocapitalize
                                        v-model="mc_signatory.remarks"
                                    />
                                </CCol>
                            </CRow>
                            </CCardBody>
                     </CCol>
                 </CRow>
             </template>
        </CModal>

        <CModal
            :show.sync="routing_history"
            color="primary"
            size="xl"
            :closeOnBackdrop="false"
            >
            <template #header>
                <h5> <font-awesome-icon icon="folder-tree"/> Internal Attached with other workflow </h5>
                <CButton 
                    class="pull-right" 
                    color="light" 
                    shape="pill" 
                    size="sm"
                    @click="routing_history = false"
                >
                    <font-awesome-icon icon="times"/>
                </CButton>
            </template>
            <template #body-wrapper>
                <CCardBody>
                    <CDataTable
                        hover
                        responsive
                        border
                        sorter
                        pagination
                        items-per-page-select
                        :items="used_in_routing"
                        :fields="used_in_routing_fields"
                        :loading="table_loading"
                    >
                    <template #first_signatory_info="{item}">
                        <td class="p-1">
                             <small class="text-muted">
                                Name : {{ item.first_signatory }}
                                <br>
                                <div v-if="changeDateFormat(item.signed_at)">
                                  Signed At: {{ changeDateFormat(item.signed_at) }}
                                </div>
                            </small>
                        </td>
                    </template>
                    <template #date_created="{item}">
                        <td class="p-1">
                             <small class="text-muted">
                                {{ changeDateFormat(item.date_created) }}
                            </small>
                        </td>
                    </template>
                    <template #progress="{item}">
                        <td class="p-1">
                                <span style="margin-top: 15px;">
                                    <small class="float-left" style="font-size: 60%;">Signatory</small>
                                    <small class="float-right" style="font-size: 60%;" v-if="item.signatory_ratio">{{ item.signatory_ratio }}</small>
                                </span>
                                <CProgress 
                                    style="height: 20px; margin-top: 15px;"
                                    variant="striped" 
                                    animated
                                >
                                    <CProgressBar 
                                    :color="getColorStatus(slug)" 
                                    :value="(item.percentage != null) ? parseInt(item.percentage) : 0"> {{ item.percentage }}%</CProgressBar>
                                </CProgress>
                        </td>
                    </template>
                    <template #action="{item}">
                        <td style=" text-align: center; " class="p-1">
                            <div v-if="!item.new_tab_permission">---</div>
                            <CButton 
                                class="rounded-pill"
                                color="primary" 
                                size="sm"
                                v-if="item.new_tab_permission"
                                @click="ValidateOpenNewWindow(item.id,item.status)"
                                v-c-tooltip="{content:'Open in New Tab', placement: 'top'}"
                            > 
                                <font-awesome-icon icon="external-link-alt"/>
                            </CButton>
                        </td>
                    </template>
                    </CDataTable>
                </CCardBody>
            </template>
            <template #footer>
                <hr hide>
            </template>
        </CModal>


    </div>
</template>
<script>
import DocumentRoutingJS from './js/document-routing';
export default {
    name: 'DocumentRoutingsTabsLists',
    // props: ['viewPreference'],
    props: {
        viewPreference: {
            type: String,
        },
        cancelledData: {
            type: Object,
            required: false,
        },
        completedData: {
            type: Object,
            required: false,
        },
        parentData: {
            type: Object,
            required: true
        },
        lastRefreshData: {
            type: Object,
            required: true
        }

    },
    data() {
            // console.log(viewPreference);
        return {
            routing_history: false,
            routing_history_open_new_tab_validation: null,
            used_in_routing: [],
            used_in_routing_fields: [
                {key: 'used_in_routing', label: 'Reference #'},
                {key: 'created_by', label: 'Created By'},
                {key: 'date_created', label: 'Date Created'},
                {key: 'first_signatory_info', label: 'First Signatory' },
                {key: 'current_signatory', label: 'Current Signatory'},
                {key: 'template', label: 'Template' },
                {key: 'category', label: 'Category' },
                {key: 'progress', filter: false },
                {key: 'status', label: 'Status'},
                {key: 'action', filter: false},
            ],

            multiple_change_signatory: false,
            
            
            for_signing_templates: [],
            available_signatories: [],
            no_available_signatories: false,
            mc_signatory: {
                selected_template: null,
                selected_signatory: null,
                remarks: ""
            },

            available_routings: [],
            available_signatory_requirements:[],

            selected_routings:[],
            selected_requirements:[],

            table_loading:  true,
            data_loaded: false,
            documents: [],
            table_documents: [],
            number_of_pages:0,
            total_records:0,
            per_page:0,
            current_page: 1,
            slug: null,
            selected_document: null,
            signatory_locked: false,
            category: null,
            search: {},
            dashboard_reference_number: [],
            prev_page:null,
            isNextPageClicked:false,
            isRemoveFilterClicked:false,


            draft_data:{},
            signing_data:{},
            processing_data:{},
            completed_data:{},
            cancelled_data:{},

            draft_data_loaded:false,
            signing_data_loaded:false,
            processing_data_loaded:false,
            completed_data_loaded:false,
            cancelled_data_loaded:false,
            
            documents_data_loaded:[],

            draft_current_page:1,
            signing_current_page: 1,
            processing_current_page: 1,
            completed_current_page: 1,
            cancelled_current_page: 1,
            documenets_loaded: [],
            statusMap: {
                'completed-list': { 
                data: 'completed_data',
                dataLoaded: 'completed_data_loaded',
                dataCurrentPage: 'completed_current_page',
                parentData: 'completedData',
                },
                'cancelled-list': { 
                data: 'cancelled_data',
                dataLoaded: 'cancelled_data_loaded',
                dataCurrentPage: 'cancelled_current_page',
                parentData: 'cancelledData',
                }
            },
            removed_row:null,
            isAlreadyFetched:false,
        }
    },
    created() {
            this.dashboard_reference_number = this.$route.query.dashboard_reference_number
    },
    computed: {
        display_last_refresh_date: function () {
            switch(this.slug) {
                case 'draft-list': 
                    return this.lastRefreshData.drft
                case 'signing-list': 
                    return this.lastRefreshData.sgnng
                case 'processing-list': 
                    return this.lastRefreshData.prcssng
                case 'completed-list': 
                    return this.lastRefreshData.cmpltd
                case 'cancelled-list': 
                    return this.lastRefreshData.cnclld
            }
        },
        document_list_fields: function () {
             let custom_fields= [
                { key: 'reference_number'} ,
                { key: 'description'} ,
                { key: 'document_template_name', _classes :'p-1'},
                { key: 'for_signing_by', _classes: (this.slug != 'processing-list') ? 'd-none p-1' :'p-1' },
                { key: 'created_by', _classes :'p-1' },
                { key: 'datetime_created',label: 'Date Created'},
                { key: 'datetime_updated',label: 'Date Updated'},
                { key: 'progress', filter: false },
                { key: 'action', filter: false}
            ]
            if(this.slug == 'draft-list'){
                custom_fields = custom_fields.filter(field => field.key !== 'datetime_updated');
            }
           
            return custom_fields;
        },
        allowed_changed: function () {
            return (this.mc_signatory.selected_template &&
                        this.mc_signatory.remarks &&
                            this.selected_routings.length > 0 &&
                                this.mc_signatory.selected_signatory ||
                                    (this.selected_requirements.length > 0 && this.available_signatory_requirements.length > 0)) ? true : false;
        }
    },
    methods: {
        refreshDataInParentFile: function () {
            this.$emit('REFRESH_DATA');
        },
        toggleRoutingHistoryModal: function (parameter) {
            this.routing_history = true
            this.table_loading = true
            this.$Progress.start()
            axios.post('/drs/document-routing/routing-history/'+parameter, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.used_in_routing = []
                    for(let i = 0; i < response.data.data.data.length; i++) {
                        if(response.data.data.data[i].user_id != response.data.data.user_id && response.data.data.data[i].status === 'Draft'){
                            this.routing_history_open_new_tab_validation = false
                        } else {
                            this.routing_history_open_new_tab_validation = true
                        }
                        let data = {
                            id: response.data.data.data[i].id,
                            used_in_routing: response.data.data.data[i].used_in_routing,
                            status: response.data.data.data[i].status,
                            created_by: response.data.data.data[i].created_by,
                            template: response.data.data.data[i].template,
                            category: response.data.data.data[i].category,
                            signatory_ratio:  response.data.data.data[i].signatory_ratio,
                            percentage: response.data.data.data[i].percentage != null ? parseInt(response.data.data.data[i].percentage) : 0,
                            first_signatory: response.data.data.data[i].first_signatory != null ? response.data.data.data[i].first_signatory : '',
                            signed_at: response.data.data.data[i].signed_at != null ? response.data.data.data[i].signed_at : '',
                            current_signatory: response.data.data.data[i].current_signatory != null ? response.data.data.data[i].current_signatory : '',
                            date_created: response.data.data.data[i].created_at,
                            new_tab_permission: this.routing_history_open_new_tab_validation
                        }
                        this.used_in_routing.push(data)
                    }
                    this.table_loading = false
                    this.$Progress.finish()
                }
            })
        },
        ValidateOpenNewWindow: function(id,status){
            switch(status) {
                case 'Draft':
                    this.slug = 'draft-list'
                break;

                case 'On Process':
                    this.slug = 'processing-list'
                break;

                default:
                    this.slug = 'completed-list'
                break;
            }
            this.openNewWindow(id);
        },
        viewDocument: function(item, index, column, event){
            if(column != 'action'){
                this.$emit('LIST_TO_DETAILS', {id: item.id, current_page: this.current_page, isFromList: true} )
            }
        },
        previewFile: function(routing_id, reference_number) {
            let file_link = `${this.$backend_url_with_auth}/file-viewer/internal-attachments/${routing_id}/${DocumentRoutingJS.encodedAndCleanFileName(reference_number)}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`
            window.open(file_link, '_blank');
        },
        downloadFile: function(routing_id, reference_number) {
            let file_link = `${this.$backend_url_with_auth}/file-viewer/internal-attachments/${routing_id}/${DocumentRoutingJS.encodedAndCleanFileName(reference_number)}?destination=D&token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`
            window.open(file_link, '_blank');
        },
        submitChangeSignatory: function () {
            let _data = {
                document_template_id: this.mc_signatory.selected_template.id,
                remarks: this.mc_signatory.remarks,
                document_routing_header_ids: this.selected_routings.map(i=>Number(i)),
                document_template_signature_id: this.mc_signatory.selected_signatory.document_template_signature_id,
            }
            if(this.selected_requirements.length > 0) {
                _data.signature_requirement_ids = this.selected_requirements
            }
            this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: "You are trying to change the signatory of multiple routings.",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: () => { 
                        this.$Progress.start()
                        return axios.post('/drs/document-routing/save-multiple-signatory', _data, {validateStatus: () => true})
                        .then(response => {
                            if ( response.status == 200 ) {
                                this.$swal({
                                    icon: "success",
                                    title: "Success!",
                                    text: `Changing of signatory success!`,
                                })
                                this.multiple_change_signatory = false;
                                this.getDocuments();
                            }
                        })
                    }
                }).then((result) => {
                    if(!result.value){
                        this.$swal('Cancelled!','','error');
                    }
                })
                return;
        },
        getDocuments: function(object = null) {
            
            this.$emit('LIST_LOADED_FALSE')
            /**
             * Codeblock by Genesis
             * Set the next page button disabled by default.
             * ---------
             * to revert, just comment out this codeblock.
             */
            //  if(this.slug == 'completed-list' || this.slug == 'processing-list') {
            //     this.$nextTick(() => {
            //         var nav = document.getElementById("pajineyt");
            //         nav.childNodes[0].children[1].className = 'page-item disabled'
            //     })
            // }
            let slugInfo;
            let slugData;
            let slugDataLoaded;
            let slugDataCurrentPage;
            let slugParentData;

            if(this.slug == "completed-list" || this.slug == "cancelled-list"){
                slugInfo = this.statusMap[this.slug];
                slugData = slugInfo.data;
                slugDataLoaded= slugInfo.dataLoaded;
                slugDataCurrentPage= slugInfo.dataCurrentPage;
                slugParentData= slugInfo.parentData;
            }

            let url;
            this.table_loading = true;
            this.data_loaded =  false;
            this.documents = [];
            this.total_records = 0;

            this.$Progress.start()

            if(object && object.reference_number) { this.search.reference_number = object.reference_number} else { delete this.search.reference_number }
            if(object && object.date_from) { this.search.date_from = object.date_from} else { delete this.search.date_from }
            if(object && object.date_to) { this.search.date_to = object.date_to} else { delete this.search.date_to }
            if(object && object.document_template_category_id) { this.search.document_template_category_id = object.document_template_category_id} else { delete this.search.document_template_category_id }
            if(object && object.user_id) { this.search.user_id = object.user_id} else { delete this.search.user_id }
            
            if(this.dashboard_reference_number) { this.search.dashboard_reference_number = this.dashboard_reference_number} else { delete this.search.dashboard_reference_number} 
            
            if(!this.isNextPageClicked && !this.isRemoveFilterClicked && (this.slug == "completed-list" || this.slug == "cancelled-list")){
                if(Object.keys(this.search).length == 0 && this[slugDataLoaded] && slugInfo){
                    this.parentData[slugParentData][this.current_page] = this.parentData[slugParentData][this.current_page].filter(item => item.id !== this.removed_row);
                    var currentPageData;
                    if(this.parentData[slugParentData][this.current_page].length == 0 && this.current_page != 1){
                        this.current_page = this.current_page - 1;
                        currentPageData = this.parentData[slugParentData][this.current_page]
                        this.display_loaded_data(this.parentData[slugParentData][this.current_page]);
                    }else{
                        currentPageData = this.parentData[slugParentData][this.current_page]
                        this.display_loaded_data(this.parentData[slugParentData][this.current_page]);
                    }

                    this.total_records = currentPageData.length <= 0 ? 0 : currentPageData[0].total_records;
                    this.total_records = this.total_records <= 12 ? this.parentData[slugParentData][this.current_page].length : this.total_records;

                    if(this.slug == 'completed-list' || this.slug == 'processing-list') {
                        this.$nextTick(() => {
                            let nav = document.getElementById("pajineyt"); 
                            currentPageData.length <= 0 ? null : this.handlePaginationAvailability(currentPageData[0].hasNextPageUrl, nav);
                        })
                    }
                    this.documents = currentPageData;
                    this.isAlreadyFetched = false
                    return
                }
            }

            if(this.isRemoveFilterClicked){
                this.search = {};
                this.draft_data_loaded = false;
                this.signing_data_loaded = false;
                this.processing_data_loaded = false;
                this.completed_data_loaded = false;
                this.cancelled_data_loaded = false;
            }

            axios.post(`/drs/document-routing/${this.slug}?page=${this.current_page}`, this.search, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200 && (response.data.data && Object.hasOwn(response.data.data, 'data'))) {
                    this.table_documents = [];
                    this.total_records=response.data.data.total ? response.data.data.total : 0 
                    this.per_page=response.data.data.per_page
                    this.slug == "completed-list" || this.slug == "cancelled-list" ? this[slugData][this.current_page] = [] : '';
                    
                    // this.documents = response.data.data.data
                    for (let i = 0; i < response.data.data.data.length; i++) {
                        let document_list = {
                            document_template_color: response.data.data.data[i].document_template_color,
                            document_template_name: response.data.data.data[i].document_template_name,
                            document_template_category: response.data.data.data[i].document_template_category,
                            id: response.data.data.data[i].id,
                            reference_number:  response.data.data.data[i].reference_number,
                            description:  response.data.data.data[i].description,
                            signatory_ratio:  response.data.data.data[i].signatory_ratio,
                            document_template_name:  response.data.data.data[i].document_template_name,
                            created_by: response.data.data.data[i].created_by_first_name+' '+response.data.data.data[i].created_by_last_name,
                            for_signing_by: response.data.data.data[i].for_signing_first_name+' '+response.data.data.data[i].for_signing_last_name,
                            datetime_created:response.data.data.data[i].created_at,
                            datetime_updated:response.data.data.data[i].updated_at,
                            return_count : response.data.data.data[i].return_count,
                            percentage: response.data.data.data[i].percentage != null ? parseInt(response.data.data.data[i].percentage) : 0,
                            history_number:  response.data.data.data[i].history_number,
                            hasNextPageUrl:response.data.data.next_page_url ? true : false,
                            total_records:response.data.data.total ? response.data.data.total : 0,
                            // -----------------
                            created_at: response.data.data.data[i].created_at,
                            updated_at: response.data.data.data[i].updated_at,
                            created_by_first_name: response.data.data.data[i].created_by_first_name,
                            created_by_last_name: response.data.data.data[i].created_by_last_name,
                            for_signing_first_name: response.data.data.data[i].for_signing_first_name,
                            for_signing_last_name: response.data.data.data[i].for_signing_last_name,
                        }
                        this.documents.push(document_list)
                        this.table_documents.push(document_list)
                        this.slug == "completed-list" || this.slug == "cancelled-list" ? this[slugData][this.current_page].push(document_list) : '';
                    }

                    if(this.slug == "completed-list" || this.slug == "cancelled-list"){
                        if(slugInfo){
                            this[slugDataLoaded] = true;
                            this[slugDataCurrentPage] = this.current_page;
                        }
                    }

                    this.$emit('update-parent-data', { key: this.slug == "completed-list" || this.slug == "cancelled-list" ? slugParentData : '',
                     value: this.slug == "completed-list" || this.slug == "cancelled-list" ? this[slugData] : '' });
                    this.isNextPageClicked = false
                    this.isRemoveFilterClicked = false
                    this.data_loaded = true
                    this.table_loading = false
                    this.isAlreadyFetched = false
                    this.$Progress.finish()
                    this.$emit('LIST_LOADED')

                    switch(this.slug) {
                        case 'draft-list': 
                            let objdrft = {
                                slug: 'draft',
                                value: this.changeDateFormat(this.currentDateTimeWithUsersTimeZone())
                            }
                            this.$emit('update-parent-last-refresh-data', objdrft);
                            break;
                        case 'signing-list': 
                            let objsgnng = {
                                slug: 'signing',
                                value: this.changeDateFormat(this.currentDateTimeWithUsersTimeZone())
                            }
                            this.$emit('update-parent-last-refresh-data', objsgnng);
                            break;
                        case 'processing-list': 
                            let objprcssng = {
                                slug: 'processing',
                                value: this.changeDateFormat(this.currentDateTimeWithUsersTimeZone())
                            }
                            this.$emit('update-parent-last-refresh-data', objprcssng);
                            break;
                        case 'completed-list': 
                            let objcmpltd = {
                                slug: 'completed',
                                value: this.changeDateFormat(this.currentDateTimeWithUsersTimeZone())
                            }
                            this.$emit('update-parent-last-refresh-data', objcmpltd);
                            break;
                        case 'cancelled-list': 
                            let objcnclld = {
                                slug: 'cancelled',
                                value: this.changeDateFormat(this.currentDateTimeWithUsersTimeZone())
                            }
                            this.$emit('update-parent-last-refresh-data', objcnclld);
                            break;
                    }
                    
                    /**
                     * Codeblock by Genesis
                     * This will manually disabled the next page in pagination
                     * when the next page url is null
                     * ---------
                     * to revert, just comment out this codeblock.
                     */
                     if(this.slug == 'completed-list' || this.slug == 'processing-list') {
                        var nav = document.getElementById("pajineyt");
                        if(!response.data.data.next_page_url) {
                            nav.childNodes[0].children[1].className = 'page-item disabled'
                        } else {
                            nav.childNodes[0].children[1].className = 'page-item'
                        }
                    }

                } else {
                    this.table_documents = [];
                    this.total_records=0
                    this.per_page=0
                    this.documents = []
                    this.data_loaded = true
                    this.table_loading = false
                    this.$Progress.finish()
                    this.$emit('LIST_LOADED')
                }
            })
        },
        viewDetails: function(id) {
            this.$emit('LIST_TO_DETAILS', {id: id, current_page: this.current_page} )
        },
        openNewWindow: function (id) {
            let route_new_tab = this.$router.resolve({path: `/${this.$store.getters['getOrganizationName']}/general-access/document-routing`, 
                                                    query: {tab: this.slug, 
                                                                reference_id: id}});
            window.open(route_new_tab.href, '_blank');  
        },
        updatePage: function() {
            let nav = document.getElementById("pajineyt");

            let slugInfo;
            let slugData;
            let slugDataCurrentPage;
            let slugParentData;

            if(this.slug == "completed-list" || this.slug == "cancelled-list"){
                slugInfo = this.statusMap[this.slug];
                slugData = slugInfo.data;
                slugDataCurrentPage= slugInfo.dataCurrentPage;
                slugParentData= slugInfo.parentData;
            }

            let isFiltered = Object.keys(this.search).length === 0 ? false : true;
                        
            if(!isFiltered && slugInfo && this.parentData[slugParentData][this.current_page] && (this.slug == "completed-list" || this.slug == "cancelled-list")) {
                this.table_loading = true;
                this.data_loaded =  false;
                this.documents = [];

                this.$Progress.start()

                var childData = this[slugData][this.current_page] || [];
                var data = Object.keys(childData).length == 0 ? this.parentData[slugParentData][this.current_page] : childData;

                this.table_documents = data;
                this[slugDataCurrentPage] = this.current_page;
                this.per_page = 12;
                this.documents = data;
                if(this.slug == 'completed-list' || this.slug == 'processing-list') {
                    this.handlePaginationAvailability(data[0].hasNextPageUrl, nav);
                }
                this.data_loaded = true
                this.table_loading = false
                this.$Progress.finish()
            }else {
                if(!this.isAlreadyFetched){
                    this.isNextPageClicked = true;
                    isFiltered ? this.getDocuments(this.search) : this.getDocuments();
                }

            }
        },
        getMyForSigningTemplateName: function () {
            this.multiple_change_signatory = true 
            this.for_signing_templates = [];
            axios.get('/drs/document-template/user-active-list', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    for(let i = 0; i < response.data.data.length; i++) {
                        let option = {
                            id: response.data.data[i].id,
                            name: response.data.data[i].name,
                            signatory_lock: response.data.data[i].signatory_lock
                        }
                        this.for_signing_templates.push(option)
                    }
                }
            })
        },
        getNewSignatory: function() {
            this.available_signatories = []
            
            this.signatory_locked = false;

            if(this.mc_signatory.selected_template.signatory_lock == '1') {
                return this.signatory_locked = true;
            }
            
            this.$Progress.start();
            axios.get('/drs/document-routing/change-multiple-signatory-list/'+this.mc_signatory.selected_template.id,  {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    let responseData = response.data.data;
                    this.no_available_signatories = responseData.length <= 0 ? true : false
                    for (let i = 0; i < responseData.length; i++) {
                        let _row = {
                            document_template_signature_id: responseData[i].id,
                            available_routings_for_change: responseData[i].user.document_routing_change_signatory_list_views,
                            signatory_user_id: responseData[i].user_id,
                            signatory_name: responseData[i].user.first_name + ' ' + responseData[i].user.last_name,
                            signatory_requirements:responseData[i].document_signature_requirements,
                        }
                        this.available_signatories.push(_row)
                    }
                    this.$Progress.finish();
                }
            })
        },
        clearChangeSignatoryForm: function() {
            this.mc_signatory.selected_template = null
            this.mc_signatory.selected_signatory = null
            this.mc_signatory.remarks =  ""

            this.for_signing_templates = [];
            this.available_signatories = [];

            this.available_routings = [];
            this.available_signatory_requirements = [];

            this.selected_routings = [];
            this.selected_requirements = [];

        },
        display_loaded_data: function(loaded_data){
            this.table_documents = [];
            this.table_documents.push(...loaded_data);           
            this.data_loaded = true
            this.table_loading = false
            this.$Progress.finish()
            this.$emit('LIST_LOADED')
        },
        handlePaginationAvailability: function(hasNextPageUrl, nav){
            if(!hasNextPageUrl) {
                nav.childNodes[0].children[1].className = 'page-item disabled'
            } else {
                nav.childNodes[0].children[1].className = 'page-item'
            }
        },
    },
    watch: {
        '$route' (to, from) {
            if(to.query.tab) {
                this.$emit('DETAILS_TO_LIST', {id: to.query.reference_id, current_page: this.current_page} )
            }
        },
        "mc_signatory.selected_template": function(value) {

            this.signatory_locked = false;
            this.no_available_signatories = false;
            
            this.available_routings = [];
            this.available_signatory_requirements = [];

            this.mc_signatory.selected_signatory = null

            
            this.available_routings = [];
            this.available_signatory_requirements = [];

            
            this.selected_routings = [];
            this.selected_requirements = [];


            if(value) {
                this.getNewSignatory();
            }
        },
        "mc_signatory.selected_signatory": function (value) {
            
            this.available_routings = [];
            this.available_signatory_requirements = [];

            
            this.selected_routings = [];
            this.selected_requirements = [];

            if(value) {
                this.available_routings = value.available_routings_for_change;
                this.available_signatory_requirements = value.signatory_requirements;
            }
        },
        multiple_change_signatory: function(value)
        {
            if(!value) {
                this.signatory_locked = false;
                this.no_available_signatories = false;
                this.clearChangeSignatoryForm();
            }
        }
    }

}
</script>